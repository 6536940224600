import React, { useEffect, useState } from "react";
import { getFirebaseStorage } from "../../firebase";
import MobileMenu from "../MobileMenu";
import DesktopMenu from "../DesktopMenu";
import "./style.scss";

const Header = () => {
  const [logo, setLogo] = useState([]);
  const [menu, setMenu] = useState([]);
  const [menuButton, setMenuButton] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const logoRef = await await getFirebaseStorage("logoUpdated.png");
        const menuRef = await await getFirebaseStorage("menu_button_2.png");
        logoRef.getDownloadURL().then((res) => setLogo(res));
        menuRef.getDownloadURL().then((res) => setMenu(res));
      } catch (error) {}
    })();
  }, []);

  return (
    <div className="main-header">
      <div className="header-image">
        <img className="header-logo" src={logo} />
        <button className={"menu-button"} onClick={() => setMenuButton(!menuButton)}>
          {!menuButton ? 
          <img className="header-menu" src={menu} />
          : <div className="header-menu-x" >X</div>}
        </button>
      </div>
      <MobileMenu menuButton={menuButton} setMenuButton={ setMenuButton} /> 
      <DesktopMenu menuButton={menuButton} setMenuButton={ setMenuButton} /> 

    </div>
  );
};

export default Header;
