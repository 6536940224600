import React, { useState, useEffect } from "react";
import { getFirebaseStorage } from "../../firebase";
import Loading from "../Loading";
import "./style.scss";

const ImageBox = ({ image, title, content, buttonText, button2 }) => {
  const [imageUrl, setImageUrl] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const imageRef = await getFirebaseStorage(image);
  //       imageRef.getDownloadURL().then((res) => setImageUrl(res));
  //     } catch (error) {}
  //   })();
  // }, []);
  return (
    <div className="imagebox">
      {/* <div className="imagebox-image">
        <img src={imageUrl} />
      </div> */}
      <div className="imagebox-container">
        <div className="imagebox-title">{title}</div>
        <div className="imagebox-content">{content}</div>
        {button2 && <div className="imagebox-button">{button2}</div>}
      </div>
    </div>
  );
};
export default ImageBox;
