import React, { useState, useEffect } from "react";
import "./style.scss";

const Highlight = ({ photo }) => {
  const [imageUrl, setImageUrl] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        await photo.getDownloadURL().then((res) => setImageUrl(res));
      } catch (error) {}
    })();
  }, []);

  return <img style={{width: '100%'}}src={imageUrl} />;
};
export default Highlight;
