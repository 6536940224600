export const serviceOptions = [
  {
    id: "service_1",
    title: "Dry Gel Pedicure",
    price: "$30.00",
    details:
      "Pedicure includes: nail cutting and shaping, cuticle care, nourishing cuticle oil, nail and skin buffing, all natural aromatic lotion for fresh feet all day! Top it all off with a beautiful gel nail polish of your choice. Please ensure feet are clean before your appointment! Nail art is priced at time of appointment."
  },
  {
    id: "service_2",
    title: "Full Encapsulated Set",
    price: "$75.00",
    details:
      "Length ($10.00) included Shape ($10.00) included Gel polish ($20.00) included. Encapsulated art provides a smooth yet dimensional look."
  },
  {
    id: "service_3",
    title: "Gel Mani",
    price: "$30.00",
    details:
      "Includes cuticle care, shaping and filing, and one gel polish! Please note that all art is priced at the time of your appointment!"
  },
  {
    id: "service_4",
    title: "Tanisha Nails Special",
    price: "$70.00",
    details:
      "Length ($10.00) included Shape ($10.00) included Gel polish ($20.00) included ANY ART AND GLASS CRYSTALS ARE INCLUDED!!! ( The catch? You can only choose your length and shape, all else is solely up to me!) This can be GelX,  soft/hard gel, or a gel mani on natural nails."
  },
  {
    id: "service_5",
    title: "Deluxe Gel Mani",
    price: "$35.00",
    details:
      "Enjoy a relaxing gel manicure that includes cuticle clean up, filing and shaping, buffing of rough skin, and of course a hand massage! Includes one gel polish of your choice. Please note that all art is priced at the time of your appointment!"
  },
  {
    id: "service_6",
    title: "Full Aprés GelX Set",
    price: "$55.00",
    details:
      "No liquid, no smell, no electric filing! Aprés system Gel-X is a soft gel extension system that can be soaked off.  The look and wear of acrylic, but we just apply, paint and go! All shapes and lengths are available, price varies** per length. Art is priced during appointment!"
  },
  {
    id: "service_7",
    title: "GelX “Fill”",
    price: "$45.00",
    details:
      "When you need a removal and fresh set of GelX.  All shapes and lengths available, price varies."
  },
  {
    id: "service_8",
    title: "Repairs",
    price: "Price varies",
    details:
      "Please leave a note in the appointment request for what is being repaired or replaced."
  },
  {
    id: "service_9",
    title: "Kid's Manicure",
    price: "$15.00",
    details:
      "Ages 10 and under! The babies hand's need love too! Whether non-toxic Piggy Polish or a gel mani, your little one will love their fun new look!"
  },
  {
    id: "service_10",
    title: "Luminary Structure Manicure",
    price: "$45.00",
    details: "Multi-Flex Gel, a soak-able soft gel with the strength of acrylic and flexibility of hard gel that creates manicures that LAST. Ideal for growing out natural nails, Luminary's gel is a universal base that allows for techs to apply only one product to create a beautiful structured manicure, overlays, extensions, and gel manicures."
  },
  {
    id: "service_11",
    title: "Paraffin Wax Dip",
    price: "$5.00",
    details: "Holds in moisture in the skin and sloughs off dead skin cells without doing any damage to the skin at all. It’s rejuvenating and refreshing, leaving the skin incredibly smooth. A form of thermotherapy, a dip uses heat to relax soreness and bring comfort to the areas of pain. It also increases blood flow and circulation, which can result in more oxygen to these areas and relieve some pain."
  }
];
