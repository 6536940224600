import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

const DesktopMenu = ({ menuButton }) => {
  const [selectedOption, setSelectedOption] = useState("home");

  return (
    <div className={"desktopmenu"}>
      <div className="selections">
        <a href="/" aria-current="page" className={`option`}>
          Home
        </a>
        <a href="/services" aria-current="page" className="option">
          Services
        </a>
        <a href="/booking" aria-current="page" className="option">
          Booking
        </a>
        <a href="/policies" aria-current="page" className="option">
          Booking & Cancellation Policies
        </a>
      </div>
    </div>
  );
};

export default DesktopMenu;
