import firebase from "firebase/app";
import "firebase/firebase-storage";

const firebaseConfig = {
  apiKey: "AIzaSyD8p7Pubt-8jZKQEnY_DqpNTSegzLHlGNo",
  authDomain: "tanishanail.firebaseapp.com",
  projectId: "tanishanail",
  storageBucket: "tanishanail.appspot.com",
  messagingSenderId: "553594300891",
  appId: "1:553594300891:web:b74368f74d3cfce8051ffe",
  measurementId: "G-ZSR4XKBWWB",
};


!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()

// !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()

export const getFirebaseStorage = async (mediaPath) => {
  return await firebase.storage().ref(`${mediaPath}`)
};
