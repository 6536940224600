import React from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import Services from "./components/Services";
import BookingPage from "./components/BookingPage";
import Policies from "./components/Policies";

import Footer from "./components/Footer";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./style.scss";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="Inner-App">
        <Switch>
          <Route path="/" exact component={Content} />
          <Route path="/services" exact component={Services} />
          <Route path="/booking" exact component={BookingPage} />
          <Route path="/policies" exact component={Policies} />
        </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
