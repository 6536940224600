import React, { useEffect, useRef } from "react";
import "./style.scss";

const BookingPage = () => {
  return (
    <div className="booking-container">
      <div className="booking-title">BOOKING</div>
      <div>
        Thank you for choosing Tanisha Nails! Before booking an appointment,
        please{" "}
        <a href="/policies" aria-current="page">
          read my policies
        </a>
        . By booking an appointment you will be confirming that you have read
        and fully understand the booking and cancellation policies.
      </div>
      <div>
        <h3>Before Scheduling An Appointment:</h3>All appointments{" "}
        <b>must be booked at least 24 hours in advance.</b> If you would like to
        schedule an appointment that is less than 24 hours from the time of
        booking, please call the Nail Artist immediately at:{" "}
        <b>(734-740-0866)</b>, or your appointment could be canceled. Thank you!
      </div>
      <div>
        <h3 style={{ textAlign: "center" }}>A Mask is REQUIRED</h3> A mask
        absolutely required to be covering your nose and mouth AT ALL TIMES
        during your appointment Regardless if vaccinated or not to adhere to new
        CDC guidelines. A violation of this policy can result in the nail
        technician refusing service, no refund will be provided. Diabetic,
        pregnancy and kid friendly services available. Please be sure to read
        the booking & cancellation policies.
      </div>

      <div>
        <h3 style={{ textAlign: "center" }}>PAYMENTS</h3>
        <p>
          I require that all appointments be secured by credit or debit card
          with <b>a minimum of a 25% deposit</b> for an in-studio appointment
          once you no call no show. For party’s, groups and special events{" "}
          <b>a 50% deposit</b> for the estimated services requested is required
          at the time of booking.
        </p>
        <p>
          Appointments are considered booked once payment information is
          received and processed.
          <b> Deposits MUST BE PAID 24 hours ahead of the appointment.</b> If
          you do not pay the deposit you will forfeit your appointment time.
        </p>
      </div>

      <div>
        <a
          className="embed"
          href="https://www.styleseat.com/tanishanails/fromwidget?badge=button"
          target="_new"
        >
          <p>BOOK NOW WITH</p>
        </a>
      </div>
    </div>
  );
};
export default BookingPage;
