import React from "react";
import "./style.scss";

const Loading = () => (
  <div className="loading">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
);
export default Loading;
