import React, { useEffect, useState } from "react";
import { getFirebaseStorage } from "../../firebase";
import "./style.scss";

const Footer = () => {
  const [Image, setImage] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const storageRef = await await getFirebaseStorage("instagram-icon.png");
        storageRef.getDownloadURL().then((res) => setImage(res));
      } catch (error) {}
    })();
  }, []);

  return (
    <footer className="footer-main">
      <div className="footer-container">
        <div className="footer-header">
          <div className="footer-socials">
            <div
              className="footer-option"
              onClick={() =>
                window.open("https://www.instagram.com/tanishanails/", "_blank")
              }
            >
              <img src={Image} alt="footer-img" />
            </div>
          </div>
        </div>
        <div className="footer-contact">
          <p style={{ fontFamily: "Quicksand, sans-serif" }}>
            Copyright © 2021 Tanisha Nails LLC - All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
