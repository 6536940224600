import React from "react";
import "./style.scss";

const PriceBox = ({ service }) => {
  const { id, title, price, details } = service;
  return (
    <div className="pricebox" key={id}>
      <div className="pricebox-header">
        <div className="pricebox-title">{title}</div>
        <div className="pricebox-price">{price}</div>
      </div>
      <div className="pricebox-details">{details}</div>
    </div>
  );
};
export default PriceBox;
