import React from "react";
import PriceBox from "../PriceBox";
import { serviceOptions } from "../../serviceList";
import "./style.scss";

const Services = () => (
  <div className="services">
    <span className="services-title">Services and Price List</span>
    {serviceOptions.map((service) => (
      <PriceBox service={service} />
    ))}
    <div className="services-silent">
      <div className="title-silent">Silent Appointment</div>

      <p>
        *Please leave tech a note when booking as to which service you are
        seeking! Can be applied to any service!*
      </p>
      <p>
        At Tanisha Nails we value your mental health! It can be uncomfortable to
        sit in someone's home with them alone for an hour, especially if you are
        unfamiliar with each other!
      </p>
      <p>
        Book a Silent Appointment today if you'd like time for quiet and
        relaxation while the tech concentrates on your nails, with no pressure
        of conversation!
      </p>
      <p>
        Our time together is about YOUR self-care, whatever that looks like for
        you! No judgment, no stress. Let's create an experience YOU are
        comfortable with!
      </p>
      <p>
        Please note, very minimal coversation will of course need to take place
        to establish what is wanted on the nails, as well as allowing the tech
        to check in on your satisfaction with her progress on your hands!
      </p>
    </div>
  </div>
);
export default Services;
