import React from "react";
import ImageBox from "../ImageBox";
import ImageSlider from "../ImageSlider";
import LazyLoad from "react-lazyload";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Content = () => (
  <div className="content">
    <LazyLoad height={250}>
      <ImageBox
        image={""}
        title={""}
        content={""}
        buttonText={""}
        button2={
          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: "40px" }}>
              <a
                href="/services"
                aria-current="page"
                style={{
                  background: "#55595c",
                  color: "#ffffff",
                  border: "1px solid #c8c8c8",
                  font: "bold 14px Arial",
                  cursor: "pointer",
                  height: "75px",
                  fontSize: "24px",
                  borderRadius: "10px",
                  textDecoration: "none",
                  width: "250px",
                  boxShadow: "rgb(255 255 255 / 50%) 0px 0px 10px 0px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <p>Price List</p>
              </a>
            </div>
            <div
              style={{
                marginBottom: "40px",
              }}
            >
              <a
                className="embed"
                href="https://www.styleseat.com/tanishanails/fromwidget?badge=button"
                target="_new"
              >
                <p>BOOK NOW WITH</p>
              </a>
            </div>
          </div>
        }
      />
    </LazyLoad>
    <div className="schedule">
      <div className="schedule-title">Hours of Operation</div>
      <div className="date">
        <div className="day">Monday</div>{" "}
        <div className="hours">
          {" "}
          <b>CLOSED</b>
        </div>
      </div>
      <div className="date">
        <div className="day">Tuesday</div>{" "}
        <div className="hours">
          {" "}
          <b>CLOSED</b>
        </div>
      </div>
      <div className="date">
        <div className="day">Wednesday</div>{" "}
        <div className="hours">
          {" "}
          <b>3pm-8pm</b>
        </div>
      </div>
      <div className="date">
        <div className="day">Thursday</div>{" "}
        <div className="hours">
          {" "}
          <b>10am-8pm</b>
        </div>
      </div>
      <div className="date">
        <div className="day">Friday</div>{" "}
        <div className="hours">
          {" "}
          <b>10am-8pm</b>
        </div>
      </div>
      <div className="date">
        <div className="day">Saturday</div>{" "}
        <div className="hours">
          {" "}
          <b>12pm-8pm</b>
        </div>
      </div>
      <div className="date">
        <div className="day">Sunday</div>{" "}
        <div className="hours">
          {" "}
          <b>9am-1pm</b>
        </div>
      </div>
    </div>
    <div
      style={{
        textAlign: "center",
        margin: "30px 0",
        fontStyle: "italic",
        fontSize: "30px",
      }}
    >
      Options to Choose From
    </div>
    <ImageSlider />

    <div style={{ margin: "30px 31px", fontSize: "24px", fontStyle: "italic" }}>
      <p>
        Choose from a wide variety of colors! You can do something classy like a
        french mani, or you may be feeling bold - choosing glass crystal
        encrusted stiletto nails, maybe pixie crystals with glitter, or even
        seasonal decals.
      </p>
      <p>
        You have hundreds of options and designs to choose from. Your only
        problem will be choosing which one to go with!
      </p>
    </div>
  </div>
);
export default Content;
