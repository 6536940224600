import React from "react";
import "./style.scss";

const MobileMenu = ({ menuButton }) => {
  const location = window.location.pathname;
  return (
    <div className={menuButton ? "mobilemenu" : "mobilemenu-off"}>
      <div className="container">
        <div className="selections">
          <a href="/" aria-current="page" className={location === '/' ? 'option-selected' : 'option'}>Home</a>
          <a href="/services" aria-current="page" className={location === '/services' ? 'option-selected' : 'option'}>Services</a>
          <a href="/booking" aria-current="page" className={location === '/booking' ? 'option-selected' : 'option'}>Booking</a>
          <a href="/policies" aria-current="page" className={location === '/policies' ? 'option-selected' : 'option'}>Booking & Cancellation Policies</a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
