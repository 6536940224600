import React from "react";
import "./style.scss";

const Policies = () => {
  return (
    <div className="policies">
      <h3>BOOK YOUR APPOINTMENT 24-HOURS IN ADVANCE</h3>
      <p>
        All appointments must be booked at least 24 hours in advance. If you
        would like to schedule an appointment that is less than 24 hours from
        the time of booking, please call the Nail Artist immediately at:
        <b>(734)-740-0866)</b>, or your appointment could be canceled.
      </p>
      <h3>AFTER HOUR CHARGES</h3>
      <p>
        Our salon hours are from 10:30 a.m. to 7:00 p.m. We are happy to make
        accommodations for early or late evening appointments. Please note, for
        this service accommodation, an additional fee will be charged. The
        additional fee amount will be between 20%-30% of the total cost of
        services, depending on when the appointment is scheduled.
      </p>
      <p>
        The client is responsible to confirm those charges with the artist
        before booking your appointment. If you have any questions, please call
        the Nail Artist immediately at: (734-740-0866)
      </p>

      <h3>FIRST TIME CLIENTS</h3>
      <p>
        You must book a consultation before a service is provided. Consultations
        can take place prior to an appointment, same day. If you are a first
        time client, I would prefer that you remove your current enhancements.
        Please understand, I need to access the current state of your nails, for
        your safety and mine. Please respect my wishes and have your nails
        safely soaked off by the previous salon, or remove them yourself with
        100% pure acetone in a glass or metal bowl. I also, for safety reasons,
        do not remove other salons acrylic.{" "}
      </p>

      <p>
        If after a phone consultation the nail artist agrees to do a fill on
        your nails which were previously done by another salon, please be
        advised that I cannot guarantee how long your nails will actually last.
        This is because I have no control over how your nails were originally
        done by another salon, and this plays a major role in lifting. If the
        nail growth is past what we would consider a “fill”, please note you
        will be charged at the full set price. Also, the nail artist reserves
        the right to decide if she has enough time to do the nails that are
        considered past the “fill” condition. A refund will not be provided and
        you will be charged in full set price.
      </p>

      <h3>PAYMENTS</h3>
      <p>
        I require that all appointments be secured by credit or debit card with
        a minimum of a 25% deposit for an in-studio appointment once you no call no show. For party’s,
        groups and special events a 50% deposit for the estimated services
        requested is required at the time of booking. Appointments are
        considered booked once payment information is received and processed.
        Deposits MUST BE PAID 24 hours ahead of the appointment. If you do not
        pay the deposit you will forfeit your appointment time.
      </p>
      <h3>
        YOU CAN CANCEL/RESCHEDULE WITHIN 24 HOURS OF SCHEDULED APPOINTMENT
      </h3>
      <p>
        Your appointments are very important to me, and it is reserved
        especially for you. I understand that sometimes schedule adjustments are
        necessary. Therefore, I respectfully request appointments be canceled or
        rescheduled at least 24 hours before your scheduled appointment only by
        going online or over the phone. Notice via text or voicemail is not
        accepted and will not be considered as a valid rescheduling of your
        appointment.
      </p>
      <p>
        If you cancel within 24 hours, you may reschedule. If you cancel within
        less than a 24 hour time frame, you will be required to pay in full in
        advance for a rescheduled appointment. All deposits are NON-REFUNDABLE.
        If the deposit is not made 24 hours ahead of the appointment, the
        appointment will be cancelled.
      </p>
      <h3>LATE SHOWS</h3>
      <p>
        I utilize every minute of your appointment time to ensure that your
        nails are PERFECT!! If you are late, you may not be able to get exactly
        what you wanted on your nails. If it’s your first time coming, please
        make yourself familiar with the area well before your appointment time.
        After you are 15 minutes late, you will need to RESCHEDULE and you will
        be charged HALF PRICE and your deposit will be lost. If you are going to
        be late, PLEASE LET ME KNOW AS EARLY AS POSSIBLE by calling the Nail
        Artist at: 734-740-0866.
      </p>
      <h3>NO SHOWS</h3>
      <p>
        Any no shows will not receive their deposit back. I will not reschedule
        with you. The cancellation policy allows me time to inform my standby
        guests of any availability, as well as keeping my schedule filled, thus
        better serving everyone.
      </p>
      <h3>EXCESS/WAITING FEE</h3>
      <p>
        Please be mindful that the nail technician(s) may have other client’s
        scheduled after the appointment so it’s important that he/she be able to
        start on time. Otherwise, the service may have to be rushed/shortened
        and/or all services may not be able to be complete, in which case a
        refund will not be given.
      </p>
      <p>
        If the nail technician(s) is held up due to waiting, ongoing
        interruptions and/or conditions with hands/feet that make the service
        time longer than usual, the client will be billed for the additional
        time at a rate of $10 at the start of each 15 minute interval unless a
        different rate is noted in the service agreement via email or text (any
        versal communications are not accepted.)
      </p>
      <h3>PARKING</h3>
      <p>
        The client is responsible for all parking-related fees, including but
        not limited meters, tolls, parking & valet expenses incurred while
        services are being provided. The nail technician is not responsible for
        your vehicle or where you park it. The nail technician is not
        responsible for any fees or tickets received due to illegal parking. For
        your safety and mine, I ask for enough disclosure to be given at the
        time of booking your appointment regarding any medical issues and/or
        conditions you or the person you are booking on behalf of may have.
      </p>

      <h3>PROFESSIONAL DEMEANOR</h3>
      <p>
        My utensils are always cleaned and sanitized to provide clients with a
        top rate experience. As a company, I do not tolerate indecent exposure,
        sexual harassment, flirting, violence and/or verbal abuse. If any of
        these take place, we have the right to refuse or stop service at any
        point, a refund will not be issued and criminal charges may be filed if
        and when applicable.
      </p>
      <h3>WAIVER OF LIABILITY/CLEAN INSTRUMENTS AND SUPPLIES</h3>
      <p>
        All services are completely optional and have been requested by the
        individual, agent of an individual or party host on behalf of his/her
        guests. Tanisha Nails LLC makes every effort to ensure that clients are
        not subjected to any bacterial/fungal infections by using new files,
        buffers and foot files on each person in addition to disinfecting all
        non-disposable tools in hospital-grade disinfectant between each client.
        I use the highest quality cosmetic grade products, if the client is
        allergic to anything or may develop any kind of allergies during or
        after services are being rendered, Tanisha Nails LLC is not liable.
      </p>
      <p>
        The Client releases Tanisha Nails LLC and our nail technician of any and
        all liability as a direct or indirect result of services rendered. We
        use products like pure acetone, polish remover, rubbing alcohol, etc.,
        which can damage your valuables. If any damage occurs during service,
        neither Tanisha Nails LLC nor our nail technician can be held liable,
        and are not responsible for any damage that may occur, and will not be
        liable or responsible to replace or pay for your damaged items. I
        reserve the right, in my sole discretion, to change these Terms and
        Conditions at any time by posting the changes on the Site. Any changes
        are effective immediately upon posting to the Site.
      </p>
    </div>
  );
};
export default Policies;
