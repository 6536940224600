import React, { useState, useEffect, lazy } from "react";
import { getFirebaseStorage } from "../../firebase";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
import Loading from "../Loading";
import Highlight from "../Highlight"
import "./style.scss";

const ImageSlider = () => {
  const [highlightRefs, setHighlightRefs] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const highlightStorageRef = await (
          await getFirebaseStorage("Images/highlights2")
        ).listAll();

        setHighlightRefs(highlightStorageRef.items);
      } catch (error) {}
    })();
  }, []);

  return (
    <div>
      <Slider
        fade={true}
        infinite={true}
        speed={500}
        lazyLoad={true}
        slidesToScroll={1}
        arrows={true}
        slidesToShow={1}
        className="slides"
      >
        { highlightRefs.length > 0 ? highlightRefs.map( (photo) => <Highlight photo={photo} />) : <Loading />}
      </Slider>
    </div>
  );
};
export default ImageSlider;
